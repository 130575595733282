import { createRef, Component } from "react";
import "@draft-js-plugins/linkify/lib/plugin.css";
import Editor from "@draft-js-plugins/editor";
import { EditorState } from "draft-js";
import createInlineToolbarPlugin from "@draft-js-plugins/inline-toolbar";
import createLinkifyPlugin from "@draft-js-plugins/linkify";
import createAutoListPlugin from "draft-js-autolist-plugin";
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  HeadlineOneButton,
  HeadlineTwoButton,
  HeadlineThreeButton,
  UnorderedListButton,
  OrderedListButton,
} from "@draft-js-plugins/buttons";

import "@draft-js-plugins/inline-toolbar/lib/plugin.css";

import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import Immutable from "immutable";
import ScreenNote from "../../../common/components/ScreenNotes";
import HoverLinkItem from "./HoverLinkItem";

const blockRenderMap = Immutable.Map({
  happyTimeBlock: {
    element: "div",
  },
  sadTimeBlock: {
    element: "div",
  },
});

const linkifyPlugin = createLinkifyPlugin({
  target: "_blank",
  rel: "noreferrer noopener",
  theme: { link: "hover:underline" },
});
const autoListPlugin = createAutoListPlugin();

const cancelEditElementId = "cancel_edit_element";

function printDoc(content) {
  return `<!DOCTYPE html>
<html lang="de">
<head>
<style type="text/css">
  body {
    font-family: 'Karla', sans-serif;
    font-size: 21px;
    line-height: 30px;
    padding: 20px;
  }
  ul {
   list-style: initial;
   margin-left: 22px;
  }
  h1 {
    font-size: 52px;
    line-height: 58px;
    font-weight: bold;
  }
  h2 {
    font-size: 32px;
    line-height: 38px;
    font-weight: bold; }
  h3 {
    font-weight: bold;
  }
</style>
</head>
<body id="body">
  ${content}
</body>
</html>`;
}

class FGEditor extends Component {
  constructor(props) {
    super(props);

    if (props.hideToolbar) {
      this._inlineToolbar = false;
    } else {
      const inlineToolbarPlugin = createInlineToolbarPlugin();
      this._inlineToolbar = inlineToolbarPlugin;
      this.plugins = [inlineToolbarPlugin, autoListPlugin, linkifyPlugin];
    }

    const initValue = props.value === "" ? "<p></p>" : props.value;
    const contentState = stateFromHTML(initValue);
    this.state = {
      editorState: EditorState.createWithContent(contentState),
      editorStatePrevious: EditorState.createWithContent(contentState),
      isReadOnly: true,
      stickyHeader: false,
      has_content: initValue !== "<p></p>",
      disable_focus: false,
      initEditor: false,
    };
    this.handleBlur = this.handleBlur.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.onChange = this.onChange.bind(this);
    this.focus = this.focus.bind(this);
    this.handleEditorState = this.handleEditorState.bind(this);
    this.cancelEditValues = this.cancelEditValues.bind(this);
    this.cancelEditValuesScreenNote =
      this.cancelEditValuesScreenNote.bind(this);
    this.print = this.print.bind(this);
    this.getSuccessNote = this.getSuccessNote.bind(this);
    this.closeSuccessNote = this.closeSuccessNote.bind(this);
    this.containerRef = createRef();
    this.editor = createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    window.addEventListener("scroll", this.listenToScroll);
  }

  componentDidUpdate(previousProps, prevState) {
    if (previousProps.value !== this.props.value && !prevState.initEditor) {
      const htmlState = stateFromHTML(this.props.value);
      const newEditorState = EditorState.createWithContent(htmlState);
      // set start default values
      this.setState({
        editorState: EditorState.moveFocusToEnd(newEditorState),
        editorStatePrevious: EditorState.createWithContent(htmlState),
        has_content: this.props.value !== "<p></p>",
        disable_focus: false,
        initEditor: true,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    window.removeEventListener("scroll", this.listenToScroll);
  }

  handleClickOutside(e) {
    const container = this.containerRef.current;
    // if click is inside it is not outside, so skip
    // GETS CALLED TWICE FOR SOME REASON
    if (
      !container ||
      !container.checkVisibility() ||
      container.contains(e.target)
    ) {
      return;
    }
    this.handleBlur();
  }

  handleEditorState(state, focus = false) {
    let { editorStatePrevious } = this.state;
    const html = stateToHTML(this.state.editorState.getCurrentContent());
    const hasContent = html !== "<p><br></p>";

    if (state) {
      editorStatePrevious = this.state.editorState;
    }

    if (focus) {
      this.editor.current.focus();
    }

    this.setState({
      isReadOnly: state,
      editorStatePrevious,
      has_content: hasContent,
    });
  }

  /**
   * save to hoc component (redux)
   */
  handleBlur() {
    const { editorState } = this.state;
    const html = stateToHTML(editorState.getCurrentContent());
    this.props.onSave(html);

    this.setState({
      disable_focus: true,
      isReadOnly: true,
      editorStatePrevious: this.state.editorState,
    });
  }

  /**
   * handle onChange
   * @param editorState
   */
  onChange(editorState) {
    const currentContentState = this.state.editorState.getCurrentContent();
    const newContentState = editorState.getCurrentContent();
    let has_content = false;
    if (currentContentState !== newContentState) {
      const html = stateToHTML(editorState.getCurrentContent());
      if (html !== "<p><br></p>") {
        has_content = true;
      }
      this.props.onChange(html);
      this.setState({
        editorState,
        has_content,
      });
    } else {
      this.setState({
        editorState,
      });
    }
  }

  /**
   * show success note
   * @returns {*}
   */
  getSuccessNote() {
    return (
      <ScreenNote
        type="success"
        message="Deine Notizen wurden erfolgreich gespeichert!"
        closeCallback={this.closeSuccessNote}
        undo
        functionCallbackUndo={this.cancelEditValues}
      />
    );
  }

  cancelEditValues() {
    const html = stateToHTML(
      this.state.editorStatePrevious.getCurrentContent(),
    );
    this.props.onChange(html);
    this.setState({
      editorState: this.state.editorStatePrevious,
      isReadOnly: true,
    });
    this.props.resetForceToSaveNote();
  }

  cancelEditValuesScreenNote() {
    this.cancelEditValues();
    this.setState({
      disable_focus: false,
    });
  }

  /**
   * set the focus to the component
   */
  focus(e) {
    const { disable_focus } = this.state;
    const cancelEditElement = document.getElementById(cancelEditElementId);
    if (
      e.target.id !== cancelEditElementId &&
      !(cancelEditElement && cancelEditElement.contains(e.target)) &&
      !disable_focus
    ) {
      this.editor.current.focus();
    }
  }

  listenToScroll() {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll >= 260 && this.state && !this.state.stickyHeader) {
      this.setState({
        stickyHeader: true,
      });
    }

    if (winScroll < 260 && this.state && this.state.stickyHeader) {
      this.setState({
        stickyHeader: false,
      });
    }
  }

  /**
   * close the error note layer
   */
  closeSuccessNote() {
    this.props.resetForceToSaveNote();
  }

  print() {
    const content = document.getElementById(this.props.print_id);
    const pri = document.getElementById("ifmcontentstoprint").contentWindow;
    pri.document.open();
    pri.document.write(printDoc(content.innerHTML));
    pri.document.close();
    pri.focus();
    pri.print();
  }

  renderEditor() {
    const { isReadOnly, editorState } = this.state;
    const { print_id } = this.props;
    const toolbarClasses = isReadOnly
      ? "__editor_wrapper view_mode"
      : "__editor_wrapper edit_mode";
    const doesNotHaveToolbar = !this._inlineToolbar;

    if (doesNotHaveToolbar) {
      return (
        <div id={print_id}>
          <Editor
            editorState={this.state.editorState}
            onChange={this.onChange}
            plugins={this.plugins}
            ref={this.editor}
            placeholder={this.props.placeholder}
            readOnly={isReadOnly}
            blockRenderMap={blockRenderMap}
          />
        </div>
      );
    }

    const { InlineToolbar } = this._inlineToolbar;

    return (
      <div id={print_id} className={toolbarClasses}>
        <Editor
          editorState={editorState}
          onChange={this.onChange}
          plugins={this.plugins}
          ref={this.editor}
          placeholder={this.props.placeholder}
          readOnly={isReadOnly}
          blockRenderMap={blockRenderMap}
          allowUndo
        />
        <InlineToolbar>
          {
            // may be use React.Fragment instead of div to improve perfomance after React 16
            (externalProps) => (
              <div>
                <BoldButton {...externalProps} />
                <ItalicButton {...externalProps} />
                <UnderlineButton {...externalProps} />
                <HeadlineOneButton {...externalProps} />
                <HeadlineTwoButton {...externalProps} />
                <HeadlineThreeButton {...externalProps} />
                <UnorderedListButton {...externalProps} />
                <OrderedListButton {...externalProps} />
              </div>
            )
          }
        </InlineToolbar>
      </div>
    );
  }

  /**
   * render
   * @returns {*}
   */
  render() {
    const { isReadOnly, stickyHeader, has_content } = this.state;

    const editor = this.renderEditor();
    return (
      <div
        className="fgEditor"
        onClick={(e) => {
          this.focus(e);
        }}
        ref={this.containerRef}
      >
        <div className={`editor-control ${stickyHeader ? "sticky" : ""}`}>
          <div className="left">
            {isReadOnly ? (
              <HoverLinkItem
                level="plain"
                disabled={false}
                defaultImage="/assets/images/stift_dunkel.png"
                hoverImage="/assets/images/stift_weiss.png"
                text="Notizen bearbeiten"
                onClick={() => {
                  this.handleEditorState(false, true);
                }}
              />
            ) : (
              <HoverLinkItem
                level="success"
                disabled={false}
                defaultImage="/assets/images/icon_ok.png"
                hoverImage=""
                text="speichern"
                onClick={() => {
                  this.handleEditorState(true);
                  this.handleBlur();
                }}
              />
            )}
          </div>
          <div className="right">
            {!isReadOnly ? (
              <div className="text-icon" id={cancelEditElementId}>
                <HoverLinkItem
                  level="error"
                  disabled={false}
                  defaultImage="/assets/images/icon_abbrechen.png"
                  hoverImage=""
                  text="Änderungen verwerfen"
                  onClick={() => {
                    this.cancelEditValues();
                  }}
                />
              </div>
            ) : (
              <HoverLinkItem
                level="plain"
                disabled={!has_content}
                defaultImage="/assets/images/print.png"
                hoverImage="/assets/images/print_white.png"
                text="Text Drucken"
                onClick={() => {
                  this.print();
                }}
              />
            )}
          </div>
          <div style={{ clear: "both" }} />
        </div>
        {editor}
        <iframe
          id="ifmcontentstoprint"
          title="ifmcontentstoprint"
          style={{
            height: "0px",
            width: "0px",
            position: "absolute",
            border: 0,
          }}
        />
      </div>
    );
  }
}
export default FGEditor;
