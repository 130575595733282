import {
  type ChangeEvent,
  type ChangeEventHandler,
  type MouseEvent,
  type FocusEvent,
  useState,
  useEffect,
} from "react";
import {
  SortablePane,
  Pane,
  type PaneSize,
  type PaneKey,
} from "react-sortable-pane";
import CreatableSelect from "react-select/creatable";
import FGEditor from "../../../../../common/components/sequences/FGEditor";
import { AutosizeInput } from "../../../../../common/components/generic/AutosizeInput";
import type { LabelValue, Slot } from "../../../../../features/sequence/types";
import type { Settings } from "../../../../../features/settings/types";
import { useReinitializingState } from "../../../../../common/utils/hooks";

type InputChangeType = "text" | "title";

type Props = {
  panekey: string;
  timeBlocks: Slot["data"];
  settings: Settings;
  onTextChange: (
    paneIndex: number,
    changeType: InputChangeType,
    text: string,
  ) => void;
  onTextBlur: (
    paneIndex: number,
    changeType: InputChangeType,
    text: string,
  ) => void;
  slotHeight: number;
  timeslotDurationInMinutes: number;
  newPaneCreated: boolean;
  handleOnResize: (id: number, duration: number) => void;
  handleOnReOrder: (newBlocks: Slot["data"]) => void;
  handleDeleteClick: (paneIndex: number) => void;
  update_material: (paneIndex: number, tags: LabelValue[]) => void;
  material_preselect: Array<LabelValue>;
};

type State = {
  taglist_open: boolean;
  pane_move: boolean;
  dragged_elem: string | null;
  is_sortable: boolean;
};

function ManagedInput(props: {
  autoFocus: boolean;
  value: string;
  onBlur: ChangeEventHandler<HTMLInputElement>;
  setTitleText: (text: string) => void;
}) {
  const [val, setVal] = useReinitializingState<string>(props.value);
  return (
    <AutosizeInput
      type="text"
      className="font-bold border-0 bg-transparent text-sm max-w-[300px]"
      name="title"
      value={val}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        setVal(e.target.value);
        props.setTitleText(e.target.value);
      }}
      autoFocus={props.autoFocus}
      onBlur={props.onBlur}
      placeholder="Artikulation"
    />
  );
}

/**
 * mak: debounce helper fuer den pane text
 * (erkennt den onBlur nicht)
 * @param value
 * @param delay
 */
function usePaneDebounceText<T>(value: T, delay: number): T {
  const [paneDebounceText, setPaneDebounceText] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setPaneDebounceText(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return paneDebounceText;
}

function SortableTimeItems(props: Props) {
  const [state, setState] = useState<State>({
    taglist_open: false,
    pane_move: false,
    dragged_elem: null,
    is_sortable: true,
  });

  const [paneInputText, setPaneInputText] = useState("");
  const [paneInputType, setPaneInputType] = useState("");
  const [activeIndex, setPaneActiveIndex] = useState(0);
  const paneDebounceText = usePaneDebounceText(paneInputText, 500); // 1 Sekunde Debounce
  useEffect(() => {
    if (paneDebounceText) {
      if (paneInputType === "text") {
        props.onTextBlur(activeIndex, "text", paneDebounceText);
      }
      if (paneInputType === "title") {
        props.onTextBlur(activeIndex, "title", paneDebounceText);
      }
    }
  }, [paneDebounceText]);

  const setPaneTitleText = (index: number, text: string) => {
    setPaneInputText(text);
    setPaneInputType("title");
    setPaneActiveIndex(index);
  };
  const setPaneText = (index: number, text: string) => {
    setPaneInputText(text);
    setPaneInputType("text");
    setPaneActiveIndex(index);
  };

  const handleDragStart = (id: string) => {
    setState({ ...state, is_sortable: true });
    setState({ ...state, pane_move: true, dragged_elem: id });
  };

  const handleDragStop = (order: string[]) => {
    if (state.pane_move) {
      const old_items = props.timeBlocks;
      const new_list: Slot["data"] = [];

      order.forEach((item) => {
        old_items.forEach((l) => {
          if (Number(l.id) === Number(item)) {
            new_list.push(l);
          }
        });
      });
      props.handleOnReOrder(new_list);
      setState({
        ...state,
        pane_move: false,
        dragged_elem: null,
      });
    }
  };

  const handleOnResize = (key: PaneKey, data: PaneSize) => {
    const { timeslotDurationInMinutes } = props;
    let duration = (data.height / props.slotHeight) * timeslotDurationInMinutes;

    let index = 0;
    const { timeBlocks: items } = props;

    let old_duration = 0;
    items.forEach((l, i) => {
      if (Number(l.id) === Number(key)) {
        index = i;

        // old height
        old_duration = l.duration;
      }
    });

    duration =
      (old_duration / timeslotDurationInMinutes) * props.slotHeight +
      data.height;

    duration = (duration / props.slotHeight) * timeslotDurationInMinutes;

    props.handleOnResize(Number(index), duration);
  };

  const handleDeleteClick = (e: MouseEvent, id: number) => {
    e.preventDefault();
    props.handleDeleteClick(id);
  };

  const onMenuOpen = () => {
    setState({ ...state, taglist_open: true });
  };

  const onMenuClose = () => {
    setState({
      ...state,
      taglist_open: false,
    });
  };

  const getMaterialTags = (i: number) => {
    const timeslot = props.timeBlocks[i];
    return timeslot && Array.isArray(timeslot.material)
      ? timeslot.material
      : [];
  };

  const { dragged_elem, is_sortable } = state;
  const { timeslotDurationInMinutes, timeBlocks, newPaneCreated } = props;

  const edit_on = state.taglist_open ? " on " : "close";
  const pane_move_class = !state.pane_move ? " no_move " : "";

  // artikulations taktung 100 = 5 min (20px = 1 minute)
  let taktung = 100;
  if (props.settings) {
    if (props.settings.settings.taktung) {
      taktung = parseInt(props.settings.settings.taktung);
    }
  }

  const panekey = `${props.panekey}-pane-${timeBlocks.length}`;

  return (
    <SortablePane
      className="timeslot-item-wrapper"
      direction="vertical"
      margin={0}
      onResizeStop={(_, key, __, ___, d) => {
        handleOnResize(key, d);
      }}
      onDragStop={(_, __, ___, order) => {
        handleDragStop(order);
      }}
      onDragStart={(_, id) => {
        handleDragStart(String(id));
      }}
      onOrderChange={() => {}}
      dragHandleClassName="mover"
      disableEffect={false}
      isSortable={is_sortable}
      key={panekey}
    >
      {timeBlocks.map((tslot, i) => {
        const tslotkey = tslot.id;
        const height =
          (tslot.duration / timeslotDurationInMinutes) * props.slotHeight;
        // mak: wieder rein wenn wir den key wieder aktivieren:const index = Number(i);
        const autofocus = newPaneCreated && i === timeBlocks.length - 1;
        const text = tslot.text ? tslot.text : "";
        const drag_active_css =
          dragged_elem && String(dragged_elem) === String(tslot.id)
            ? " drag_active"
            : "";

        // HINT: we make sure the passed initial value of the FGEditor is passed to the key as well
        // to make it rerender if the value changes (otherwise it is behaving like defaultValue)
        return (
          <Pane
            className={`pane-item ${pane_move_class} ${drag_active_css}`}
            id={tslot.id}
            key={tslotkey}
            style={{ height }}
            size={{ width: "100%", height }}
            resizable={{ x: false, y: true, xy: false }}
            grid={[1, taktung]}
          >
            <div className="timeline-slot">
              <div className="header-edit">
                <a
                  href="#"
                  onClick={(e) => {
                    handleDeleteClick(e, i);
                  }}
                >
                  löschen
                </a>
              </div>

              <div className="slot-wrapper">
                <div className={`editor-wrapper ${edit_on}`}>
                  <div className="material-edit">
                    <div className="header-title">Material</div>
                  </div>

                  <CreatableSelect
                    openMenuOnFocus
                    isMulti
                    onChange={(val) => {
                      // spread here to make value writable
                      props.update_material(i, [...val]);
                    }}
                    options={props.material_preselect}
                    placeholder="Hier Material eintragen"
                    className="cleanSelect"
                    classNamePrefix="react-select"
                    onMenuOpen={onMenuOpen}
                    onMenuClose={onMenuClose}
                    value={getMaterialTags(i)}
                  />
                </div>
                <div
                  className="mover"
                  onMouseOver={() => {
                    setState({ ...state, is_sortable: true });
                  }}
                >
                  <span className="dragger">
                    <img src="/assets/images/dragger.png" className="dragger" />
                  </span>
                </div>

                <div className="content-edit">
                  <div className="header">
                    <ManagedInput
                      value={tslot.title}
                      autoFocus={autofocus}
                      setTitleText={(titleText) => {
                        // mak: das gleeiche Problem wie beim anderen text
                        setPaneTitleText(i, titleText);
                      }}
                      onBlur={(event: FocusEvent<HTMLInputElement>) => {
                        const { value } = event.target;
                        setState({ ...state, is_sortable: true });
                        props.onTextBlur(i, "title", value);
                      }}
                    />
                    <span className="duration">
                      {" "}
                      | {Math.round(tslot.duration)} Minuten
                    </span>
                  </div>

                  <div className="text">
                    {/*
                  HINT: we make sure the passed initial value of the FGEditor is passed to the key as well
                  to make it rerender if the value changes (otherwise it is behaving like defaultValue)

                   Marko: das war der key den wir entfern haben
                   key={`dayslot-edit-pane-${index}-${text}`}

                  */}
                    <FGEditor
                      placeholder="Hier Details eintragen"
                      value={text}
                      hideToolbar={false}
                      autoFocus
                      onChange={(html: string) => {
                        setPaneText(i, html);
                        props.onTextChange(i, "text", html);
                      }}
                      onBlurCall={(html: string) => {
                        setState({ ...state, is_sortable: true });
                        props.onTextBlur(i, "text", html);
                      }}
                      onClick={() => {
                        setState({ ...state, is_sortable: true });
                      }}
                    />
                  </div>
                </div>
                <div className="clearfix" />
              </div>
            </div>
          </Pane>
        );
      })}
    </SortablePane>
  );
}
export default SortableTimeItems;
